.non_visible {
    display: none;
}

.hide {
    opacity: 0;
}

.container-center {
   width: 100%;
   display: flex;
   flex-direction: column;
   line-height: 1.5;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
}

.mask {
    width: 100%;
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    z-index: 100;
    transition: 0.6s;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loader {
    margin: auto;
    width: 75px;
    height: 75px;
    border:10px solid rgba(13, 71, 161, 0.6);
    border-radius: 50%;
    border-left-color:#0D47A1;
    animation: loader 1.3s linear infinite;
    z-index: 101;
}

@keyframes loader {
     100% {
      transform: rotate(360deg);
     }
}

@font-face {
  font-family: "Trebuchet MS";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Trebuchet MS";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Trebuchet MS";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

html,
body {
  height: 100%;
  min-width: 320px;
  font-size: 16px;
}

body {
  line-height: 1;
  font-family: "Trebuchet MS";
  color: #000;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: "Trebuchet MS";
  font-size: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  background-color: inherit;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

._container {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
}
@media (max-width: 767.98px) {
  ._container {
    max-width: 1220px;
    padding: 0 10px;
  }
}

._heading {
  text-align: center;
  margin: 0px 0px 40px 0px;
}
@media (max-width: 767.98px) {
  ._heading {
    margin: 0px 0px 20px 0px;
  }
}
._heading ._title {
  color: #333;
  font-size: 36px;
  font-weight: 600;
  line-height: 106.6666666667%;
  margin: 0px 0px 12px 0px;
}
@media (max-width: 767.98px) {
  ._heading ._title {
    margin: 0px 0px 6px 0px;
  }
}
@media (max-width: 479.98px) {
  ._heading ._title {
    font-size: 28px;
  }
}
._heading ._subtitle {
  font-weight: 300;
}

._black-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #272727;
  color: #fff;
  text-transform: uppercase;
  height: 60px;
  padding: 0px 45px;
  font-weight: 500;
  border-radius: 10px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 479.98px) {
  ._black-button {
    height: 50px;
    padding: 0 35px;
    font-size: 14px;
  }
}
@media (any-hover: hover) {
  ._black-button:hover {
    background-color: #464646;
  }
}

._card-time {
  display: inline-flex;
  align-items: center;
  font-weight: 300;
  margin: 0px 0px 16px 0px;
}
@media (max-width: 991.98px) {
  ._card-time {
    margin: 0px 0px 10px 0px;
  }
}
._card-time svg {
  height: 18px;
  width: 37px;
}

._card-price {
  font-weight: 500;
  font-size: 22px;
  color: #383838;
  margin: 0px 0px 36px 0px;
}
@media (max-width: 991.98px) {
  ._card-price {
    font-size: 20px;
    margin: 0px 0px 24px 0px;
  }
}

._card-hot-price {
  font-weight: 500;
  font-size: 22px;
  color: #383838;
  margin: 0px 0px 32px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._card-hot-price img {
  margin: 0px 6px 0px 0px;
  height: 20px;
}
@media (max-width: 991.98px) {
  ._card-hot-price {
    font-size: 20px;
    margin: 0px 0px 14px 0px;
  }
}
@media (max-width: 479.98px) {
  ._card-hot-price {
    margin: 0px 0px 20px 0px;
  }
}

._card-last-price {
  background-color: #a9a9a9;
  color: #fff;
  display: inline-block;
  padding: 5px;
  margin: 0px 0px 12px 0px;
}
@media (max-width: 479.98px) {
  ._card-last-price {
    margin: 0px 0px 6px 0px;
  }
}

._letter {
    text-align: left;
    padding: 70px 90px;
    margin: 0 auto;
    max-width: 960px;
    background-color: #f7f7f7;
    background-repeat: repeat-x;
    background-size: 20%;
    background-position: bottom;
}

._card-last-price span {
  text-decoration: line-through;
  font-weight: 500;
}

@media (max-width: 479.98px) {
  ._letter {
    padding: 40px 40px 40px 50px;
    background-size: 30%;
  }
}

._quotes {
  position: absolute;
  left: -40px;
  top: -20px;
}
@media (max-width: 767.98px) {
  ._quotes {
    left: -30px;
    top: -15px;
    height: 15px;
    width: 20px;
  }
}
._quotes path {
  fill: #787878;
}

._title {
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0px 0px 24px 0px;
  line-height: 1.2;
}
@media (max-width: 767.98px) {
  ._title {
    font-size: 18px;
  }
}

._signature {
  font-weight: 300;
  font-size: 18px;
}
@media (max-width: 767.98px) {
  ._signature {
    font-size: 16px;
  }
}
._signature span {
  font-weight: 500;
}

._label {
  font-weight: 300;
  margin-bottom: 10px;
  display: block;
  font-size: 18px;
}

._input {
  font-size: 20px;
  display: block;
  height: 56px;
  padding: 0 10px;
  border: 2px solid #ccc;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f8f8f8;
  width: 100%;
}

.main {
  background-color: #f7f7f7;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 0;
  min-height: 100px;
  box-shadow: 0 0 5px 1px #bababa;
  z-index: 10;
}
@media (max-width: 1200px) {
  .header {
    padding: 20px 0;
    min-height: 80px;
  }
}
@media (max-width: 479.98px) {
  .header {
    padding: 16px 0;
    min-height: 60px;
  }
}
.header__container {
  width: 100%;
  padding: 0 40px;
}
@media (max-width: 1200px) {
  .header__container {
    padding: 0 15px;
  }
}
.header__body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  display: inline-block;
  height: 80px;
  flex: 0 0 155px;
  margin: 0 30px;
}
@media (max-width: 1200px) {
  .header__logo {
    margin: 0;
  }
}
@media (max-width: 479.98px) {
  .header__logo {
    height: 50.6666666667px;
    flex: 0 0 102.5px;
  }
}
.header__logo img {
  height: 100%;
  width: 100%;
}
@media (max-width: 1200px) {
  .menu {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: -100%;
    left: 0;
    transition: top 0.3s ease 0s;
  }
  .menu._active {
    opacity: 1;
    overflow: auto;
    visibility: visible;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 70px 30px;
    margin: 80px 0px 0px 0px;
    max-height: calc(100vh - 80px);
  }
}
@media (max-width: 1200px) and (max-width: 991.98px) {
  .menu._active {
    padding: 50px 0;
  }
}
@media (max-width: 1200px) and (max-width: 767.98px) {
  .menu._active {
    padding: 30px 0;
  }
}
@media (max-width: 1200px) and (max-width: 479.98px) {
  .menu._active {
    padding: 20px 0;
    margin: 60px 0px 0px 0px;
    max-height: calc(100vh - 60px);
  }
}
@media (max-width: 1200px) {
  .menu::before {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 10;
    height: 100%;
    width: 100%;
    content: "";
    display: block;
    background-color: #000;
    opacity: 0.9;
  }
}
.menu__body {
  position: relative;
  z-index: 100;
}
.menu__list {
  display: flex;
  align-items: center;
}
@media (max-width: 1200px) {
  .menu__list {
    flex-direction: column;
  }
}
.menu__item {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  padding: 10px 0;
}
.menu__item:not(:last-child) {
  margin: 0px 24px 0px 0px;
}
@media (max-width: 1200px) {
  .menu__item:not(:last-child) {
    margin: 0px 0px 0px 0px;
  }
}
@media (min-width: 1200px) and (any-hover: hover) {
  .menu__item:hover .menu__arrow svg {
    transform: rotate(90deg);
  }
  .menu__item:hover .menu__arrow svg path {
    fill: #faaabc;
  }
  .menu__item:hover .menu__link {
    color: #faaabc;
  }
  .menu__item:hover .menu__sub-list {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0px);
    pointer-events: auto;
  }
}
.catalog__link {
    color: inherit;
}
.menu__item._hover .menu__arrow svg {
  transform: rotate(90deg);
}
.menu__item._hover .menu__arrow svg path {
  fill: #faaabc;
}
.menu__item._hover .menu__link-arrow {
  color: #faaabc;
}
.menu__item._hover .menu__sub-list {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, 0px);
  pointer-events: auto;
}
.menu__link-wrapper {
  display: flex;
}
@media (max-width: 1200px) {
  .menu__link-wrapper {
    flex-direction: column;
  }
}
.menu__arrow {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 8px;
}
.menu__arrow svg {
  transition: all 0.3s ease 0s;
  transform: rotate(180deg);
}
.menu__arrow svg path {
  transition: all 0.3s ease 0s;
  fill: #808080;
}
@media (max-width: 1200px) {
  .menu__arrow {
    display: none;
  }
}
.menu__link {
  display: block;
  transition: all 0.3s ease 0s;
  color: #000;
}
@media (any-hover: hover) {
  .menu__link:hover {
    color: #faaabc;
  }
}
@media (max-width: 1200px) {
  .menu__link {
    text-align: center;
    margin: 0px 0px 12px 0px;
    color: #ffffff;
    font-size: 22px;
  }
}
@media (max-width: 767.98px) {
  .menu__link {
    font-size: 20px;
  }
}
@media (max-width: 479.98px) {
  .menu__link {
    font-size: 18px;
  }
}
.menu__sub-list {
  text-align: center;
}
@media (min-width: 1200px) {
  .menu__sub-list {
    position: absolute;
    top: 100%;
    visibility: hidden;
    opacity: 0;
    left: 50%;
    background-color: #fff;
    box-shadow: 0 0 5px 1px #bababa;
    padding: 20px;
    max-width: 230px;
    min-width: 120%;
    pointer-events: none;
    transform: translate(-50%, 20px);
    transition: all 0.3s ease 0s;
  }
}
@media (max-width: 1200px) {
  .menu__sub-list {
    position: relative;
    top: 0;
    left: 0;
    margin: 0px 0px 24px 0px;
  }
}
.menu__sub-item:not(:last-child) {
  margin: 0px 0px 12px 0px;
}
.menu__sub-link {
  color: #000;
  text-transform: none;
  transition: all 0.3s ease 0s;
  white-space: normal;
  font-size: 18px;
}
@media (any-hover: hover) {
  .menu__sub-link:hover {
    text-decoration: underline;
    color: #faaabc;
  }
}
@media (max-width: 1200px) {
  .menu__sub-link {
    color: #ffffff;
    font-size: 20px;
  }
}
@media (max-width: 767.98px) {
  .menu__sub-link {
    font-size: 18px;
  }
}
@media (max-width: 479.98px) {
  .menu__sub-link {
    font-size: 16px;
  }
}

.actions {
  display: flex;
  align-items: center;
}
.actions__contact {
  margin: 0px 30px 0px 0px;
  text-align: center;
}
@media (max-width: 1200px) {
  .actions__contact {
    margin: 0px 20px 0px 0px;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 479.98px) {
  .actions__contact {
    margin: 0px 12px 0px 0px;
  }
}
.actions__tel {
  display: block;
  font-size: 18px;
  white-space: nowrap;
  color: #000;
  margin: 0px 0px 8px 0px;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .actions__tel:hover {
    color: #faaabc;
  }
}
@media (max-width: 1200px) {
  .actions__tel {
    z-index: 100;
    margin: 0;
    border-bottom: 1px dashed;
    padding-bottom: 3px;
  }
}
@media (max-width: 479.98px) {
  .actions__tel {
    font-size: 16px;
    margin: 0px 0px 0px 0px;
  }
}
.actions__tel._active {
  color: #fff;
}
@media (any-hover: hover) {
  .actions__tel._active:hover {
    color: #faaabc;
  }
}
.actions__whatsapp-icon {
  display: none;
}
@media (max-width: 1200px) {
  .actions__whatsapp-icon {
    z-index: 100;
    height: 40px;
    width: 40px;
    display: inline-block;
    margin: 0px 12px 0px 0px;
  }
  .actions__whatsapp-icon img {
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 479.98px) {
  .actions__whatsapp-icon {
    height: 30px;
    width: 30px;
    margin: 0px 4px 0px 0px;
  }
}
.actions__whatsapp-text {
  color: #000;
  display: block;
  font-size: 14px;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .actions__whatsapp-text:hover {
    color: #faaabc;
  }
}
@media (max-width: 1200px) {
  .actions__whatsapp-text {
    display: none;
  }
}
.actions__fast-call {
  height: 40px;
  display: inline-flex;
  padding: 0 30px;
  align-items: center;
  justify-content: center;
  color: #000;
  border: 3px solid #000;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
}
@media (any-hover: hover) {
  .actions__fast-call:hover {
    color: #faaabc;
    border-color: #faaabc;
  }
}
@media (max-width: 1200px) {
  .actions__fast-call {
    display: none;
  }
}

@media (max-width: 1200px) {
  .icon-menu {
    display: inline-flex;
    height: 20px;
    width: 30px;
    z-index: 110;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s ease 0s;
    margin-left: 20px;
  }
}
@media (max-width: 1200px) and (any-hover: hover) {
  .icon-menu:hover span {
    background-color: #faaabc;
  }
}
@media (max-width: 1200px) and (max-width: 479.98px) {
  .icon-menu {
    height: 16px;
    width: 22px;
  }
}
@media (max-width: 1200px) {
  .icon-menu span {
    transition: all 0.3s ease 0s;
    pointer-events: none;
    border-radius: 10px;
    display: block;
    z-index: 110;
    width: 100%;
    height: 3px;
    background-color: #000;
  }
}
@media (max-width: 1200px) and (max-width: 479.98px) {
  .icon-menu span {
    height: 2px;
  }
}
@media (max-width: 1200px) {
  .icon-menu._active {
    justify-content: center;
  }
  .icon-menu._active span {
    background-color: #fff;
    display: none;
  }
  .icon-menu._active span:first-child {
    display: block;
    transform: rotate(-45deg) translateY(50%);
  }
  .icon-menu._active span:last-child {
    display: block;
    transform: rotate(45deg) translateY(-50%);
  }
}
@media (max-width: 1200px) and (any-hover: hover) {
  .icon-menu._active:hover span {
    background-color: #faaabc;
  }
}

.catalog {
  margin: 40px 0;
}
@media (max-width: 767.98px) {
  .catalog {
    margin: 20px 0;
  }
}
.catalog__container {
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .catalog__container {
    max-width: 1000px;
  }
}
@media (max-width: 479.98px) {
  .catalog__container {
    padding: 0 10px;
    max-width: 1220px;
  }
}
.catalog__list {
  display: grid;
  grid-template-columns: repeat(8, 100px);
  justify-content: center;
  gap: 40px;
}
@media (max-width: 1200px) {
  .catalog__list {
    grid-template-columns: repeat(8, 95px);
    gap: 25px;
  }
}
@media (max-width: 991.98px) {
  .catalog__list {
    grid-template-columns: repeat(4, 160px);
  }
}
@media (max-width: 767.98px) {
  .catalog__list {
    grid-template-columns: repeat(4, 100px);
    gap: 15px;
  }
}
@media (max-width: 479.98px) {
  .catalog__list {
    grid-template-columns: repeat(2, 140px);
  }
}
.catalog__item {
  text-align: center;
  width: 100px;
}
@media (max-width: 1200px) {
  .catalog__item {
    width: 95px;
  }
}
@media (max-width: 991.98px) {
  .catalog__item {
    width: 160px;
  }
}
@media (max-width: 767.98px) {
  .catalog__item {
    width: 100px;
  }
}
@media (max-width: 479.98px) {
  .catalog__item {
    width: 140px;
  }
}
.catalog__image {
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12);
  margin: 0px 0px 12px 0px;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .catalog__image:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
  }
}
@media (max-width: 1200px) {
  .catalog__image {
    height: 95px;
    margin: 0px 0px 8px 0px;
  }
}
@media (max-width: 991.98px) {
  .catalog__image {
    height: 160px;
  }
}
@media (max-width: 767.98px) {
  .catalog__image {
    height: 100px;
  }
}
@media (max-width: 479.98px) {
  .catalog__image {
    height: 140px;
  }
}
.catalog__image img {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.catalog__title {
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .catalog__title {
    font-size: 14px;
  }
}

.delivery {
  margin-bottom: 50px;
  padding: 60px 0;
  overflow: hidden;
  position: relative;
  background-position: center;
  background-size: cover;
}

.delivery__background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 767.98px) {
  .delivery {
    margin-bottom: 30px;
    padding: 40px 0;
  }
}
@media (max-width: 479.98px) {
  .delivery {
    text-align: center;
    padding: 30px 0;
  }
}
.delivery__body {
  z-index: 10;
  position: relative;
  -moz-text-align-last: left;
       text-align-last: left;
  display: inline-block;
  margin: 0px 0px 0px 100px;
  color: #fff;
}
@media (max-width: 767.98px) {
  .delivery__body {
    margin: 0px 0px 0px 60px;
  }
}
@media (max-width: 479.98px) {
  .delivery__body {
    margin: 0px 0px 0px 0px;
  }
}
.delivery__title {
  font-size: 48px;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 300;
  margin: 0px 0px 30px 0px;
}
@media (max-width: 767.98px) {
  .delivery__title {
    font-size: 38px;
    margin: 0px 0px 20px 0px;
  }
}
@media (max-width: 479.98px) {
  .delivery__title {
    font-size: 32px;
    margin: 0px 0px 10px 0px;
  }
}
.delivery__title span {
  font-weight: 500;
}
.delivery__text {
  font-weight: 400;
  font-size: 24px;
  line-height: 1.6;
  margin: 0px 0px 50px 0px;
}
@media (max-width: 767.98px) {
  .delivery__text {
    margin: 0px 0px 30px 0px;
    font-size: 22px;
  }
}
@media (max-width: 479.98px) {
  .delivery__text {
    margin: 0px 0px 15px 0px;
    font-size: 18px;
  }
}
.delivery__text span {
  font-weight: 500;
  border-bottom: 2px dashed #fff;
}
.delivery__form {
  display: flex;
  gap: 30px;
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  .delivery__form {
    flex-direction: column;
    gap: 20px;
  }
}
.delivery__number-input {
  padding: 0 15px;
  outline: none;
  border-radius: 5px;
  height: 40px;
  width: -moz-fit-content;
  width: fit-content;
}
@media (max-width: 767.98px) {
  .delivery__number-input {
    padding: 0 10px;
    height: 35px;
  }
}
@media (max-width: 479.98px) {
  .delivery__number-input {
    padding: 0 10px;
    height: 30px;
  }
}
.delivery__number-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  height: 40px;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.3s ease 0s;
}
@media (max-width: 767.98px) {
  .delivery__number-button {
    padding: 0 30px;
    height: 35px;
  }
}
@media (max-width: 479.98px) {
  .delivery__number-button {
    padding: 0 20px;
    height: 30px;
  }
}
@media (any-hover: hover) {
  .delivery__number-button:hover {
    background-color: #bbbbbb;
  }
}
.delivery__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px;
  height: 60px;
  color: #000;
  background-color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
@media (max-width: 767.98px) {
  .delivery__button {
    padding: 0 50px;
    height: 50px;
  }
}
@media (max-width: 479.98px) {
  .delivery__button {
    padding: 0 40px;
    height: 40px;
  }
}
@media (any-hover: hover) {
  .delivery__button:hover {
    background-color: #bbbbbb;
  }
}

.flower {
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .flower {
    margin-bottom: 30px;
  }
}
.flower__container {
  text-align: center;
}
.flower__list {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  justify-content: space-between;
  gap: 60px 0;
  margin: 0px 0px 60px 0px;
}
@media (max-width: 991.98px) {
  .flower__list {
    gap: 40px 0;
  }
}
@media (max-width: 767.98px) {
  .flower__list {
    grid-template-columns: repeat(2, 48%);
    gap: 30px 0;
  }
}
@media (max-width: 479.98px) {
  .flower__list {
    margin: 0px 0px 30px 0px;
  }
}
@media (max-width: 400px) {
  .flower__list {
    grid-template-columns: 95%;
    justify-content: center;
    gap: 20px 0;
  }
}
.flower__button {
  margin: 0px 0px 60px 0px;
}
@media (max-width: 479.98px) {
  .flower__button {
    margin: 0px 0px 30px 0px;
  }
}
.card-flower {
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  box-shadow: 0 0 30px 1px #dddddd;
  transition: all 0.3s ease 0s;
  border-radius: 5px;
  overflow: hidden;
}
@media (any-hover: hover) {
  .card-flower:hover {
    box-shadow: 0 0 20px 1px #9f9f9f;
  }
}
.card-flower__link {
  display: block;
 -webkit-tap-highlight-color: transparent;
}
.card-flower__image {
  padding: 0px 0px 100% 0px;
  position: relative;
  overflow: hidden;
}
.card-flower__image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.card-flower__content {
  text-align: center;
  padding: 30px;
}
@media (max-width: 991.98px) {
  .card-flower__content {
    padding: 20px;
  }
}
.card-flower__title {
  color: #383838;
  font-size: 20px;
  margin: 0px 0px 12px 0px;
  line-height: 1.35;
}
@media (max-width: 991.98px) {
  .card-flower__title {
    font-size: 18px;
    margin: 0px 0px 6px 0px;
  }
}
.card-flower__actions {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-flower__cart {
  display: inline-flex;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #000;
  margin: 0px 16px 0px 0px;
  transition: all 0.3s ease 0s;
  padding: 10px;
}
@media (max-width: 991.98px) {
  .card-flower__cart {
    height: 40px;
    width: 40px;
    padding: 8px;
    margin: 0px 10px 0px 0px;
  }
}
@media (any-hover: hover) {
  .card-flower__cart:hover {
    background-color: #000;
  }
  .card-flower__cart:hover svg {
    stroke: #fff;
  }
}
.card-flower__cart svg {
  stroke: #000;
  height: 100%;
  width: 100%;
}
.card-flower__favourites {
  display: inline-flex;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  padding: 6px;
}
@media (max-width: 991.98px) {
  .card-flower__favourites {
    height: 40px;
    width: 40px;
    padding: 4px;
  }
}
@media (any-hover: hover) {
  .card-flower__favourites:hover svg path {
    stroke: #ff7575;
  }
}
.card-flower__favourites svg {
  height: 100%;
  width: 100%;
}
.card-flower__favourites svg path {
  transition: all 0.3s ease 0s;
}

.form-flower {
  width: 100%;
  display: flex;
  max-width: 760px;
  margin: 0 auto;
  padding: 0px 10px;
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .form-flower {
    flex-direction: column;
    align-items: center;
  }
}
.form-flower__search-wrapper {
  border: 2px solid #9b9b9b;
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 20px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .form-flower__search-wrapper {
    margin: 0px 0px 32px 0px;
  }
}
@media (max-width: 479.98px) {
  .form-flower__search-wrapper {
    height: 45px;
    padding: 14px;
    margin: 0px 0px 20px 0px;
  }
}
.form-flower__search-button {
  height: 25px;
  flex: 0 0 25px;
}
@media (max-width: 479.98px) {
  .form-flower__search-button {
    height: 20px;
    flex: 0 0 20px;
  }
}
.form-flower__search-button path {
  transition: all 0.3s ease 0s;
  fill: #797979;
}
@media (any-hover: hover) {
  .form-flower__search-button:hover path {
    fill: #0b0b0b;
  }
}
.form-flower__search {
  width: 100%;
  margin: 0 20px;
  outline: none;
  background-color: #f7f7f7;
  font-size: 18px;
}
@media (max-width: 479.98px) {
  .form-flower__search {
    margin: 0 12px;
  }
}
.form-flower__reset-button {
  flex: 0 0 25px;
  display: inline-flex;
  align-items: center;
  position: relative;
  min-height: 100%;
  cursor: pointer;
}
@media (max-width: 479.98px) {
  .form-flower__reset-button {
    flex: 0 0 20px;
  }
}
.form-flower__reset-button span {
  position: absolute;
  height: 2px;
  width: 100%;
  display: inline-block;
  background-color: #797979;
  border-radius: 5px;
  transition: all 0.3s ease 0s;
}
.form-flower__reset-button span:first-child {
  transform: rotate(45deg);
}
.form-flower__reset-button span:last-child {
  transform: rotate(-45deg);
}
@media (any-hover: hover) {
  .form-flower__reset-button:hover span {
    background-color: #0b0b0b;
  }
}
.form-flower__button-submit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 60px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 18px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #ebebeb;
  transition: all 0.3s ease 0s;
  margin: 0px 0px 0px 20px;
}
@media (max-width: 767.98px) {
  .form-flower__button-submit {
    margin: 0px 0px 0px 0px;
  }
}
@media (max-width: 479.98px) {
  .form-flower__button-submit {
    height: 45px;
    padding: 0 45px;
    font-size: 16px;
  }
}
@media (any-hover: hover) {
  .form-flower__button-submit:hover {
    background-color: #d1d1d1;
  }
}

.creator-letter {
    margin-top: 30px;
}
@media (max-width: 767.98px) {
  .creator-letter {
    margin-bottom: 30px;
  }
}
.creator-letter__container {
  background-color: #f7f7f7;
  padding: 0 15px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .creator-letter__container {
    padding: 0 10px;
  }
}
.creator-letter__subtitle {
  font-weight: 400;
  font-size: 22px;
  margin: 0px 0px 30px 0px;
  position: relative;
}
@media (max-width: 767.98px) {
  .creator-letter__subtitle {
    font-size: 20px;
    margin: 0px 0px 20px 0px;
  }
}
.creator-letter__text {
  font-weight: 400;
  font-size: 20px;
  margin: 0px 0px 50px 0px;
  line-height: 1;
}
@media (max-width: 767.98px) {
  .creator-letter__text {
    font-size: 18px;
    margin: 0px 0px 36px 0px;
  }
}
.accordion {
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .accordion {
    margin-bottom: 30px;
  }
}
.accordion__container {
  padding: 0 15px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .accordion__container {
    padding: 0 10px;
  }
}
.accordion__body {
  display: inline-block;
  max-width: 760px;
  width: 100%;
}
.item-accordion {
  background-color: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  min-height: 80px;
  padding: 15px 30px;
  font-weight: 400;
  flex-wrap: wrap;
  transition: all 0.3s ease 0s;
}
@media (max-width: 767.98px) {
  .item-accordion {
    padding: 10px 20px;
  }
}
@media (max-width: 479.98px) {
  .item-accordion {
    padding: 10px 15px;
    min-height: 60px;
  }
}
.item-accordion:not(:last-child) {
  margin-bottom: 3px;
}
.item-accordion__header-link {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  font-size: 22px;
}
@media (max-width: 479.98px) {
  .item-accordion__header-link {
    font-size: 18px;
  }
}
.item-accordion__header-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  flex: 0 0 45px;
  position: relative;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
  margin: 0px 0px 0px 30px;
}
@media (max-width: 479.98px) {
  .item-accordion__header-button {
    margin: 0px 0px 0px 15px;
    height: 35px;
    flex: 0 0 35px;
  }
}
@media (any-hover: hover) {
  .item-accordion__header-button:hover {
    background-color: #c4c4c4;
  }
}
.item-accordion__header-button span {
  position: absolute;
  display: inline-block;
  width: 22px;
  height: 2px;
  background-color: #8e8e8e;
}
@media (max-width: 479.98px) {
  .item-accordion__header-button span {
    width: 18px;
  }
}
.item-accordion__header-button span:first-child {
  transform: rotate(90deg);
}
.item-accordion__sub-list {
  flex: 0 1 100%;
  padding: 20px 20px 20px 50px;
}
@media (max-width: 767.98px) {
  .item-accordion__sub-list {
    padding: 15px 15px 15px 30px;
  }
}
@media (max-width: 479.98px) {
  .item-accordion__sub-list {
    padding: 10px 10px 10px 20px;
  }
}
.item-accordion__sub-list li {
  list-style: normal;
}
.item-accordion__sub-item {
  font-weight: 300;
  line-height: 1.5;
  font-size: 20px;
}
@media (max-width: 479.98px) {
  .item-accordion__sub-item {
    font-size: 18px;
    line-height: 1.2;
  }
}
.item-accordion__sub-item:not(:last-child) {
  margin-bottom: 8px;
}
.item-accordion__sub-item a {
  font-weight: 400;
  border-bottom: 1px solid rgb(250, 142, 70);
  color: rgb(250, 142, 70);
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .item-accordion__sub-item a:hover {
    border-bottom: 1px solid rgb(183, 73, 0);
    color: rgb(183, 73, 0);
  }
}
.item-accordion__sub-item span {
  font-weight: 400;
}

.address-letter {
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .address-letter {
    margin-bottom: 30px;
  }
}
.address-letter__container {
  padding: 0 15px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .address-letter__container {
    padding: 0 10px;
  }
}
.address-letter__text {
  font-size: 18px;
  line-height: 1.5;
  margin: 0px 0px 32px 0px;
}
.address-letter__text:first-child {
  position: relative;
}

.delivery-price {
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .delivery-price {
    margin-bottom: 30px;
  }
}
.delivery-price__container {
  padding: 0 15px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .delivery-price__container {
    padding: 0 10px;
  }
}
.delivery-price__list {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
}
.delivery-price__item {
  display: flex;
  align-items: center;
  text-align: left;
  display: grid;
  grid-template-columns: 25% 39% 36%;
}
@media (max-width: 767.98px) {
  .delivery-price__item {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.delivery-price__item:nth-child(odd) {
  background-color: #f0f0f0;
}

.item-price {
  padding: 25px;
  min-height: 68px;
  background-color: #fff;
  gap: 30px;
}
@media (max-width: 479.98px) {
  .item-price {
    gap: 15px;
    text-align: center;
    padding: 20px;
    font-size: 14px;
  }
}
.item-price:first-child {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #000;
  background-color: #f7f7f7;
}
@media (max-width: 479.98px) {
  .item-price:first-child {
    font-size: 14px;
  }
}
.item-price:last-child {
  background-color: #000;
  color: #fff;
}
.feedback-letter {
  margin-bottom: 50px;
}
@media (max-width: 767.98px) {
  .feedback-letter {
    margin-bottom: 30px;
  }
}
.feedback-letter__container {
  padding: 0 15px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .feedback-letter__container {
    padding: 0 10px;
  }
}
.feedback-letter__body {
  text-align: center;
}
.feedback-letter__text {
  text-align: left;
  position: relative;
  font-size: 20px;
  margin: 0px 0px 20px 0px;
  line-height: 1.4;
}
@media (max-width: 767.98px) {
  .feedback-letter__text {
    font-size: 18px;
  }
}
@media (max-width: 479.98px) {
  .feedback-letter__text {
    font-size: 16px;
  }
}
.feedback-letter__signature {
  margin: 0px 0px 20px 0px;
}
.footer {
  background-color: #3F4345;
}
.footer__container {
  padding: 0 15px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .footer__container {
    padding: 0 10px;
  }
}
.footer__list {
  display: flex;
  padding: 30px 0px 40px 0px;
  justify-content: space-around;
  gap: 30px;
}
@media (max-width: 767.98px) {
  .footer__list {
    flex-wrap: wrap;
  }
}
.footer__content {
  border-top: 1px dashed #c6c6c6;
  padding: 0 15px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .footer__content {
    padding: 0 10px;
  }
}
.footer__content-container {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
}
@media (max-width: 479.98px) {
  .footer__content-container {
    flex-direction: column;
    gap: 20px;
  }
}
.footer__copy {
  font-weight: 300;
  color: #c6c6c6;
  font-size: 14px;
  margin: 0px 30px 0px 0px;
}
@media (max-width: 767.98px) {
  .footer__copy {
    margin: 0px 15px 0px 0px;
    font-size: 12px;
  }
}

.footer-socials {
  display: flex;
}
.footer-socials__list {
  display: flex;
  align-items: center;
}
.footer-socials__item:not(:last-child) {
  margin: 0px 8px 0px 0px;
}
.footer-socials__link {
  display: inline-block;
}
.footer-socials__link svg path {
  transition: all 0.3s ease 0s;
  fill: #bdbdbd;
}
@media (any-hover: hover) {
  .footer-socials__link:hover path {
    fill: #faaabc;
  }
}

.item-footer {
  text-align: left;
}
@media (max-width: 767.98px) {
  .item-footer {
    text-align: center;
  }
}
.item-footer__link {
  font-size: 20px;
  display: block;
  color: #fff;
  text-transform: uppercase;
  margin: 0px 0px 16px 0px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 767.98px) {
  .item-footer__link {
    font-size: 18px;
  }
}
@media (max-width: 479.98px) {
  .item-footer__link {
    font-size: 16px;
  }
}
@media (any-hover: hover) {
  .item-footer__link:hover {
    color: #faaabc;
  }
}
.item-footer__sub-item:not(:last-child) {
  margin: 0px 0px 16px 0px;
}
.item-footer__sub-link {
  display: block;
  font-size: 18px;
  font-weight: 300;
  color: #c6c6c6;
  transition: all 0.3s ease 0s;
}
@media (max-width: 767.98px) {
  .item-footer__sub-link {
    font-size: 16px;
  }
}
@media (any-hover: hover) {
  .item-footer__sub-link:hover {
    color: #faaabc;
  }
}

.product {
  margin-bottom: 40px;
}
.header-product {
  padding: 15px 0;
}
.header-product__link {
  color: #6c6c6c;
  font-weight: 300;
  font-size: 18px;
  position: relative;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .header-product__link:hover {
    color: #ef9700;
  }
  .header-product__link:hover::after {
    color: #6c6c6c;
  }
}
.header-product__link:not(:last-child)::after {
  cursor: default;
  position: absolute;
  display: block;
  top: 6px;
  right: -24px;
  font-size: 14px;
  font-weight: 400;
  content: ">";
}
.header-product__link:not(:last-child) {
  margin-right: 36px;
}

.main-product {
  justify-content: center;
  display: flex;
  gap: 40px;
}
@media (max-width: 991.98px) {
  .main-product {
    gap: 20px;
  }
}
@media (max-width: 767.98px) {
  .main-product {
    flex-direction: column;
  }
}
.main-product__slider-wrapper {
  flex: 0 1 35%;
  display: flex;
  flex-direction: column;
}
.main-product__image-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}
@media (max-width: 767.98px) {
  .main-product__image-wrapper {
    padding-bottom: 70%;
  }
}
.main-product__image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.main-product__sliders {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.main-product__slider-image-wrapper {
  flex: 0 0 9%;
  border: 2px solid #000;
  position: relative;
  padding-bottom: 9%;
}
@media (max-width: 991.98px) {
  .main-product__slider-image-wrapper {
    flex: 0 0 13%;
    padding-bottom: 13%;
  }
}
@media (max-width: 767.98px) {
  .main-product__slider-image-wrapper {
    flex: 0 0 9%;
    padding-bottom: 9%;
  }
}
@media (max-width: 479.98px) {
  .main-product__slider-image-wrapper {
    flex: 0 0 13%;
    padding-bottom: 13%;
  }
}
.main-product__slider-image-wrapper img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.main-product__content {
  flex: 0 1 50%;
}

.product-content__title {
  font-size: 24px;
  color: #383838;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 12px;
}
.product-content__article {
  color: #777;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 24px;
}
.product-content__price-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 24px;
}
.product-content__price {
  color: #5a5a5a;
  font-size: 30px;
  font-weight: 500;
}
.product-content__favourite-button {
  display: inline-flex;
  align-items: center;
}
.product-content__favourite-button svg {
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .product-content__favourite-button svg:hover {
    fill: rgb(252, 75, 75);
  }
}
.product-content__buy-block {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  gap: 20px;
  flex-wrap: wrap;
}
.product-content__add-button {
  background-color: #2ee59d;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-flex;
  height: 45px;
  padding: 0 20px;
  align-items: center;
  border-radius: 20px;
  letter-spacing: 3px;
  color: #fff;
  transition: all 0.3s ease 0s;
}
.product-content__delete-button {
  background-color: #ac5500;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-flex;
  height: 45px;
  padding: 0 20px;
  align-items: center;
  border-radius: 20px;
  letter-spacing: 3px;
  color: #fff;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .product-content__add-button:hover {
    color: #383838;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }
}
.product-content__buy-button {
  color: #fff;
  height: 45px;
  padding: 0 15px;
  letter-spacing: 3px;
  font-size: 12px;
  font-weight: 500;
  background-color: #5a5a5a;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .product-content__buy-button:hover {
    background-color: #ffffff;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
    color: #000;
  }
}
.product-info {
  font-size: 20px;
}
.product-info__sizes {
  font-weight: 300;
  margin-bottom: 24px;
}
.product-info__sizes span {
  font-weight: 400;
  border-bottom: 1px dashed #000;
}
.product-info__structure {
  white-space: pre-line;
  font-weight: 300;
  margin-bottom: 24px;
}
.product-info__category-button {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #ACC8E5;
  color: #fff;
  height: 35px;
  display: inline-flex;
  align-items: center;
  border-radius: 30px;
  padding: 0 10px;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .product-info__category-button:hover {
    background-color: #212121;
  }
}
.product-info__tip {
  font-weight: 300;
}
.product-info__tip a {
  color: #000;
  font-weight: 400;
  border-bottom: 1px dashed #000;
}

.value {
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  margin-bottom: 40px;
}
.value__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
}
@media (max-width: 991.98px) {
  .value__list {
    gap: 30px 10px;
    grid-template-columns: 1fr 1fr;
  }
}
.value__item {
  display: inline-flex;
  align-items: center;
}
.value__item img {
  height: 50px;
  width: 80px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 479.98px) {
  .value__item img {
    height: 39px;
    width: 60px;
  }
}

.watch-also {
  margin-bottom: 40px;
}
.watch-also__title {
  text-align: center;
}
.watch-also__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}
@media (max-width: 991.98px) {
  .watch-also__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}
@media (max-width: 767.98px) {
  .watch-also__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 400px) {
  .watch-also__list {
    grid-template-columns: 1fr;
  }
}
.watch-also-card {
  background-color: #fff;
  text-align: center;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .watch-also-card:hover {
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  }
}
.watch-also-card__link {
  color: #323232;
  display: block;
}
.watch-also-card__image-wrapper {
  position: relative;
  padding-bottom: 90%;
  margin-bottom: 24px;
}
.watch-also-card__image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.watch-also-card__title {
  margin-bottom: 16px;
  font-size: 18px;
}
.watch-also-card__sizez {
  font-weight: 300;
  margin-bottom: 16px;
  font-size: 14px;
}
.watch-also-card__price {
  font-size: 20px;
  margin-bottom: 32px;
}
.watch-also-card__buy-button {
  display: inline-flex;
  align-items: center;
  margin-bottom: 24px;
  color: #000;
  font-size: 14px;
  letter-spacing: 2px;
  height: 33px;
  padding: 0 10px;
  border: 1px dashed #000;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
}
@media (any-hover: hover) {
  .watch-also-card__buy-button:hover {
    background-color: #000;
    color: #fff;
  }
}

.icon-cart__wrapper {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 10vh;
  right: 5vw;
  height: 80px;
  width: 80px;
  z-index: 30;
  cursor: pointer;
}
.icon-cart__body {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-cart__body svg {
  height: 40px;
  width: 40px;
}
.icon-cart__count {
  background-color: #000;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.popup-cart {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  z-index: 100;
}
.popup-cart__wrapper {
  z-index: 10000;
  opacity: 1;
  padding: 40px;
  max-width: 560px;
  width: 100%;
  background-color: #fff;
  margin: 10vh auto 10vh;
  position: relative;
  z-index: 1000;
}
.popup-cart__header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.popup-cart__title {
  font-size: 20px;
  font-weight: 500;
}
.popup-cart__close-button {
  height: 34px;
  width: 34px;
  position: relative;
  border-radius: 50%;
  border: 2px solid #000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .popup-cart__close-button:hover {
    border: 2px solid #faaabc;
  }
  .popup-cart__close-button:hover span {
    background-color: #faaabc;
  }
}
.popup-cart__close-button span {
  display: block;
  position: absolute;
  height: 2px;
  width: 20px;
  background-color: #000;
  transition: all 0.3s ease 0s;
}
.popup-cart__close-button span:first-child {
  transform: rotate(45deg);
}
.popup-cart__close-button span:last-child {
  transform: rotate(-45deg);
}
.popup-cart__product-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
  border-top: 1px solid #aeaeae;
  border-bottom: 1px solid #aeaeae;
  margin-bottom: 20px;
}
.popup-cart__accept-button {
  font-size: 14px;
  height: 40px;
}
.popup-cart__price {
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: end;
  margin-bottom: 50px;
}
.popup-cart__input-name-wrapper {
  margin-bottom: 20px;
}
.popup-cart__input-email-wrapper {
  margin-bottom: 20px;
}
.popup-cart__input-phone-wrapper {
  margin-bottom: 30px;
}
.popup-cart__next-button-wrapper {
  text-align: center;
}
.popup-cart__next-button {
  margin-bottom: 30px;
}
.popup-cart__address-label {
  margin-bottom: 30px;
}
.popup-cart__radio-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  margin: 0px 0px 20px 20px;
}
.popup-cart__radio-style-input {
  height: 24px;
  width: 24px;
  border: 2px solid #000;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}
.popup-cart__radio-label {
  font-size: 18px;
}
.popup-cart__adress-input {
  margin-bottom: 30px;
}
.popup-cart__date-wrapper {
  margin-bottom: 30px;
}
.popup-cart__date-input {
  max-width: 200px;
}
.popup-cart__time-wrapper {
  margin-bottom: 30px;
}
.popup-cart__checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.popup-cart__checkbox-input {
  height: 18px;
  width: 18px;
  margin-right: 6px;
}
.popup-cart__checkbox-label {
  font-size: 18px;
  display: flex;
}
.popup-cart__checkbox-label span {
  padding-top: 2px;
}
.popup-cart__product-price {
  text-align: right;
  font-size: 16px;
  margin-bottom: 6px;
}
.popup-cart__delivery-price {
  text-align: right;
  font-size: 16px;
  margin-bottom: 12px;
}
.popup-cart__last-price {
  text-align: right;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.popup-cart__create-order-button-wrapper {
  text-align: center;
}
.popup-cart__set-promo_code-button-wrapper {
  text-align: center;
  max-width: 100px;
}

._black-button-promo-code {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #272727;
  color: #fff;
  text-transform: uppercase;
  height: 50px;
  padding: 10px 45px;
  margin-bottom: 10px;
  font-weight: 500;
  border-radius: 10px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 479.98px) {
  ._black-button-promo-code {
    height: 40px;
    padding: 0 35px;
    font-size: 14px;
  }
}

.popup-item-cart {
  display: flex;
  align-items: center;
}
.popup-item-cart__image-wrapper img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-right: 16px;
}
.popup-item-cart__title {
  font-weight: 500;
  margin-right: auto;
}
.popup-item-cart__count-block {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.popup-item-cart__minus-button {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #aeaeae;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .popup-item-cart__minus-button:hover {
    border: 2px solid #262626;
  }
  .popup-item-cart__minus-button:hover span {
    background-color: #262626;
  }
}
.popup-item-cart__minus-button span {
  transition: all 0.3s ease 0s;
  margin: 0 auto;
  height: 2px;
  width: 12px;
  background-color: #aeaeae;
  display: block;
}
.popup-item-cart__input {
  width: 14px;
  height: 20px;
  margin: 0 10px;
  outline: none;
}
.popup-item-cart__plus-button {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 2px solid #aeaeae;
  display: inline-flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .popup-item-cart__plus-button:hover {
    border: 2px solid #262626;
  }
  .popup-item-cart__plus-button:hover span {
    background-color: #262626;
  }
}
.popup-item-cart__plus-button span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  height: 2px;
  width: 12px;
  background-color: #aeaeae;
  display: block;
}
.popup-item-cart__plus-button span:first-child {
  transform: translateX(-50%) rotate(90deg);
}
.popup-item-cart__price {
  white-space: nowrap;
  margin: 0 10px;
  max-width: 100px;
  width: 100%;
  text-align: center;
}
.popup-item-cart__close-button {
  display: inline-flex;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 50%;
  border: 2px solid #aeaeae;
  align-items: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
@media (any-hover: hover) {
  .popup-item-cart__close-button:hover {
    border: 2px solid #262626;
  }
  .popup-item-cart__close-button:hover span {
    background-color: #262626;
  }
}
.popup-item-cart__close-button span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  height: 2px;
  width: 12px;
  background-color: #aeaeae;
  display: block;
}
.popup-item-cart__close-button span:first-child {
  transform: translateX(-50%) rotate(45deg);
}
.popup-item-cart__close-button span:last-child {
  transform: translateX(-50%) rotate(-45deg);
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
}

.overlay {
  background-color: #000;
  opacity: 0.5;
  position: relative;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 50;
  height: 100vh;
  width: 100vw;
}

.textarea_note {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

.about-us {
  margin-bottom: 50px;
}
.about-us__cards-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 40px;
}
@media (max-width: 767.98px) {
  .about-us__cards-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 30px;
  }
}
.about-us-card__image-wrapper {
  margin-bottom: 24px;
}
.about-us-card__image-wrapper img {
  height: 55px;
  width: 55px;
}
@media (max-width: 767.98px) {
  .about-us-card__image-wrapper img {
    height: 45px;
    width: 45px;
  }
}
.about-us-card__title {
  font-size: 28px;
  font-weight: 300;
  margin-bottom: 24px;
}
@media (max-width: 991.98px) {
  .about-us-card__title {
    font-size: 26px;
  }
}
@media (max-width: 767.98px) {
  .about-us-card__title {
    font-size: 22px;
  }
}
.about-us-card__info {
  font-size: 18px;
  font-weight: 300;
}
@media (max-width: 767.98px) {
  .about-us-card__info {
    font-size: 16px;
  }
}
